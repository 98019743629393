<template>
  <v-snackbar
      ref="snackbar"
      v-model="show"
      :timeout="persistant ? -1 : 6000"
  >
    <div :class="messageType">
      <div v-if="Array.isArray(message)">
        <span v-for="(msg, index) in message" :key="index">
          {{ msg }}
        </span>
      </div>
      <span v-else>
        {{ message }} 
      </span> 
    </div>
    <template v-if="persistant" v-slot:action="{ attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        @click="show = false"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'InfoNotification',
  data() {
    return {
      show: false,
      message: '',
      messageType: '',
      persistant: false
    }
  },
  mounted() {
    auth.infoNotification = this
  },
  methods: {
    showMessage(message, persistant = false) {
      console.log(message)
      this.messageType = 'message'
      this.message = message
      this.persistant = persistant
      this.show = true
    },
    showError(message, persistant = false) {
      this.messageType = 'error'
      this.message = message
      this.persistant = persistant
      this.show = true
    },
    showResponseError(error) {
      if (error.response && error.response.data) {
        this.showError(error.response.data.message)
      } else {
        this.showError(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-snack__action > .v-snack__btn.v-btn {
  min-width: auto;
}
</style>
