<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

          <app-filter-apply-button
            v-if="filterStore.hasChanges"
            @click="applyFilter();"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                  v-model="filterStore.filter.dateStart"
                  id="dateStart"
                  :clearable="true"
                ></app-filter-date-picker>
              </div>
            </v-slide-item> 

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                  v-model="filterStore.filter.dateEnd"
                  id="dateEnd"
                  :clearable="true"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-search-list">
                <user-filter-widget
                  v-model="filterStore.filter.selectedUser"
                  :include-inactive="true"
                ></user-filter-widget>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-status-filter">
                <select-status-filter-widget 
                  v-model="filterStore.filter.status"
                  :multiple="true"
                  :filter-list="['open', 'approved', 'declined', 'deleted']"
                ></select-status-filter-widget>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-reason-filter">
                <select-filter-widget 
                  v-model="filterStore.filter.reason"
                  :label="t('reason-filter')"
                  :multiple="true"
                  :url="'absences/reasons'"
                  :chips="true"
                ></select-filter-widget>
              </div>
            </v-slide-item>
          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div v-show="dataLoaded && absencesItems.length > 0">
            <v-btn
              elevation="0"
              color="primary"
              outlined
              class="icon-left"
              :disabled="!readyToDownload"
              :loading="isDownloadingExport"
              @click="exportExcel()"
            >
              <v-icon>mdi-download</v-icon>
              {{ t('excel-export') }}
            </v-btn>
          </div>

          <div v-if="configuration && configuration.export && exportStats.approved != null">
            <v-btn
              elevation="0"
              color="primary"
              outlined
              class="icon-left"
              :disabled="exportStats.approved.not_exported === 0 || isExporting"
              :loading="isExporting"
              @click="exportApproved()"
            >
              <v-icon v-if="!isExporting">mdi-export-variant</v-icon>
              <span>{{ exportStats.approved.not_exported }} {{ t('approved-export') }} </span>
            </v-btn>
          </div>
        </v-row>

        <v-row v-if="dataLoaded && absencesItems.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
            calculate-widths
            :headers="headers"
            :items="absencesItems"
            :options.sync="options"
            :server-items-length="totalAbsences"
            :loading="loading"
            class="elevation-0"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left', 
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.title`]="{ item }">
              <span class="ellipsis"><b>{{ item.title }}</b></span>
            </template>

              <template v-slot:[`item.comment`]="{ item }">
                <v-tooltip v-if="item.comment !== ''" bottom>
                  <template v-slot:activator="{ on, attr }">
                      <v-icon
                        v-bind="attr"
                        v-on="on"
                      >
                        mdi-chat
                      </v-icon>
                  </template>
                  <span>{{ item.comment }}</span>
                </v-tooltip>
              </template>

            <template v-slot:[`item.reason`]="{ item }">
              <span>{{ item.reason }}</span>
            </template>

            <template v-slot:[`item.user`]="{ item }">
              <span class="ellipsis">{{ item.author.display_name }}</span>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <span v-if="item.allday">{{ formatDate(item.created_at) }}</span>
              <span v-if="!item.allday">
                {{ formatDate(item.created_at) }}<br>
                {{ formatTime(item.created_at) }}
              </span>
            </template>

            <template v-slot:[`item.start_date`]="{ item }">
              <span v-if="item.allday">{{ formatDate(item.start_date) }}</span>
              <span v-if="!item.allday">
                {{ formatDate(item.start_date) }}<br>
                {{ formatTime(item.start_date) }}
              </span>
            </template>

            <template v-slot:[`item.end_date`]="{ item }">
              <span v-if="item.allday">{{ formatDate(item.end_date) }}</span>
              <span v-if="!item.allday">
                {{ formatDate(item.end_date) }}<br>
                {{ formatTime(item.end_date) }}
              </span>
            </template>

            <template v-slot:[`item.archived`]="{ item }"> 
              <div style="text-align: center;">
                <v-icon v-if="item.archived">check</v-icon>
              </div>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <status-icon-widget :status="item.status" :hide-text="false"/>
            </template>

            <template v-slot:[`item.edit`]="{ item }"> 
              <router-link :to="{ name: 'AbsencesEdit', params: { id: item.id } }" class="edit-row-table">
                <v-icon
                  small
                  class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>

      </v-container>

    </div>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment'
import toolbar from '@/components/layouts/Navigation'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import SelectStatusFilterWidget from '@/components/filter-components/SelectStatusFilterWidget'
import SelectFilterWidget from '@/components/filter-components/SelectFilterWidget'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'
import AppFilterApplyRefreshButton from '@/components/vuetify/AppFilterApplyRefreshButton'
import { useAbsenceFilterStore } from '@/stores/AbsenceFilterStore'

export default {
  name: 'AbsencesIndex',
  components: {
    AppFilterApplyRefreshButton,
    SelectFilterWidget,
    SelectStatusFilterWidget,
    AppFilterApplyButton,
    UserFilterWidget,
    toolbar,
    AppFilterDatePicker,
    AppFilterSearchField,
    StatusIconWidget
  },
  setup() {
    const filterStore = useAbsenceFilterStore()
    return { filterStore }
  },
  data() {
    return {
      current: 'AppFilterDatePicker',
      configuration: null,
      loading: false,
      isDownloadingExport: false,
      isExporting: false,
      exportStats: {
        approved: null,
      },
      totalAbsences: 0,
      absencesItems: [],
      options: {},
      dataLoaded: false,
    }
  },
  async created() {
    await this.getAbsencesConfig()
  },
  computed: {
    readyToDownload: function () {
      return this.dataLoaded && !this.isDownloadingExport
    },
    headers() {
      return [
        {
          text: this.t('table.title'),
          align: 'start',
          sortable: true,
          value: 'title',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.reason'),
          value: 'reason',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          value: 'comment',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.user'),
          value: 'user',
          sortable: true,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.created_at'),
          value: 'created_at',
          sortable: true,
          width: 160,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.start'),
          value: 'start_date',
          sortable: true,
          width: 160,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.end'),
          value: 'end_date',
          sortable: true,
          width: 120,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.archived'),
          value: 'archived',
          sortable: false,
          width: 120,
          cellClass: 'text-middle',
          align: 'center'
        },
        {
          text: this.t('table.status'),
          value: 'status',
          width: 100,
          align: 'start',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 150
        }
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  methods: {
    t: function (key) {
      return this.$t('absences.list.' + key)
    },
    moment: function () {
      return moment()
    },
    async reload() {
      this.absencesItems = []
      await this.getDataFromApi()
    },
    async applyFilter() {
      this.filterStore.filterApplied()
      this.options.page = 1
      await this.reload()
    },
    async getDataFromApi() {
      this.loading = true
      this.dataLoaded = false

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

      let response = await HTTP.get('absences' + url)
      this.absencesItems = response.data.data
      this.totalAbsences = response.data.total
      this.loading = false
      this.dataLoaded = true
    },
    async getAbsencesConfig() {
      let response = await HTTP.get('absences/configuration')
      this.configuration = response.data

      if (this.configuration.export) {
        this.getExportStats()
      }
    },
    async getExportStats() {
      let response = await HTTP.get('absences/exportstats')
      this.exportStats = response.data
    },
    async exportApproved() {
      this.isExporting = true
      let response = await HTTP.get('export/absences')
      await this.getExportStats()
      this.$root.infoNotification.showMessage(response.data.message)
      this.isExporting = false
    },
    exportExcel() {
      this.isDownloadingExport = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

      HTTP.post('dataexport/excel/absences' + url, {}, {responseType: 'arraybuffer'}).then(function (response) {
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Absenzen_' + moment().format('DDMMYYYY-HHmm') + '.xlsx'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isDownloadingExport = false
      }.bind(this))
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.dateStart) {
        queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
      }
      if (this.filterStore.filter.dateEnd) {
        queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
      }
      if (this.filterStore.filter.selectedUser) {
        queryStringParts.push('q=' + this.filterStore.filter.selectedUser?.id)
      }
      if (this.filterStore.filter.status.length > 0) {
        queryStringParts.push('st=' + this.filterStore.filter.status)
      }
      if (this.filterStore.filter.reason.length > 0) {
        queryStringParts.push('reason=' + this.filterStore.filter.reason)
      }
      if (sortBy) {
        queryStringParts.push('sortBy=' + (sortBy[0] ?? ''))
        let sort = sortDesc[0] ? 'desc' : 'asc'
        queryStringParts.push('sortDesc=' + sort)
      }
      if (page) {
        page = page ? page : 1
        queryStringParts.push('page=' + page)
      }
      if (itemsPerPage) {
        queryStringParts.push('itemsPerPage=' + itemsPerPage)
      }
      return '?' + queryStringParts.join('&')
    }
  }
}
</script>

<style scoped lang="scss">
.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.status-approved-exported {
  position: relative;

  i {
    position: absolute;
    font-size: 18px;
    left: -8px;
    top: -5px;
    color: var(--v-success-base);
  }
}
</style>
